
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { barChartOutline, businessOutline, downloadOutline, flashlightOutline, flashOutline, heartCircleOutline, informationCircleOutline, rainyOutline, thermometerOutline, trendingUpOutline, waterOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Resumen',
        url: '/',
        iosIcon: barChartOutline,
        mdIcon: barChartOutline
      },
      {
        title: 'Temperatura',
        url: '/temperature',
        iosIcon: thermometerOutline,
        mdIcon: thermometerOutline
      },
      {
        title: 'Humedad',
        url: '/humidity',
        iosIcon: waterOutline,
        mdIcon: waterOutline
      },
      {
        title: 'Pressión',
        url: '/pressure',
        iosIcon: downloadOutline,
        mdIcon: downloadOutline
      },
      {
        title: 'Viento',
        url: '/wind',
        iosIcon: trendingUpOutline,
        mdIcon: trendingUpOutline
      },
      {
        title: 'Luz',
        url: '/light',
        iosIcon: flashlightOutline,
        mdIcon: flashlightOutline
      },
      {
        title: 'Calidad del aire',
        url: '/air-quality',
        iosIcon: heartCircleOutline,
        mdIcon: heartCircleOutline
      },
      {
        title: 'Lluvia',
        url: '/rain',
        iosIcon: rainyOutline,
        mdIcon: rainyOutline
      },
      {
        title: 'Relámpagos',
        url: '/lightning',
        iosIcon: flashOutline,
        mdIcon: flashOutline
      },
      {
        title: 'AEMET',
        url: '/aemet',
        iosIcon: businessOutline,
        mdIcon: businessOutline
      },
      {
        title: 'Sobre el Proyecto',
        url: '/about',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleOutline
      }
    ];

    const path = window.location.pathname.split('folder/')[1];

    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
